import styled from 'styled-components';
import React from 'react';
import Heading from './Heading';
import { space, SpaceProps } from 'styled-system';
import { scales } from './types';

const HeadingWrapper = styled.div`
    display: flex;
    justify-content: center;
    width: 100%;
    ${ space }
`;

const HeadingSpaceWrapper = styled.div`
    border-bottom: 4px solid ${ ( { theme } ) => theme.colors.primary };
    margin: 0 auto;
    padding-bottom: 5px;
`;

const UnderlinedHeading: React.FC<SpaceProps> = ( props ) => {
    const { children, ...otherProps } = props;

    return (
        <HeadingWrapper { ...otherProps }>
            <HeadingSpaceWrapper>
                <Heading scale={ scales.XL }>{ children }</Heading>
            </HeadingSpaceWrapper>
        </HeadingWrapper>
    );
};

UnderlinedHeading.defaultProps = {
    mb: '32px'
};

export default UnderlinedHeading;
