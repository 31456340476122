import React, { useMemo } from 'react';
import { useParams } from 'react-router-dom';
import { Container, Heading, Page, PageHeader, Text } from 'components';
import articles from 'views/Articles/tempArticles';
import { IArticle } from '../../interfaces';
import styled from 'styled-components';
import { formatDate } from '../../utils/dateTime';
import ArweaveArticle from './components/ArweaveArticle';

const DetailsWrapper = styled.div`
    color: ${ ( { theme } ) => theme.colors.textSubtle };
    margin: 1rem 0;
    padding-bottom: 16px;
    border-bottom: 1px solid ${ ( { theme } ) => theme.colors.cardBorder };
`;

const Author = styled.span`
    color: black;
`;

const Article: React.FC = () => {
    const { slug } = useParams<{ slug?: string }>();

    const article = useMemo<IArticle>( () => {
        return articles.find( a => a.slug === slug );
    }, [
        slug
    ] );

    return (
        <Page>
            <Container>
                <PageHeader/>

                { article ? (
                    <>
                        <Text
                            color='textSubtle'
                            mb={ 10 }
                        >
                            { article.categories.join( ', ' ) }
                        </Text>

                        <Heading>
                            { article?.title }
                        </Heading>

                        <DetailsWrapper>
                            posted by <Author>{ article?.author.name }</Author>, { formatDate( article.date ) } · 8 views
                        </DetailsWrapper>

                        <ArweaveArticle objectId={ article?.arweaveObjectId }/>
                    </>
                ) : (
                    <Text>Oops, article not found.</Text>
                ) }
            </Container>
        </Page>
    );
};

export default Article;