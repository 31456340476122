import React from 'react';
import { Link } from 'react-router-dom';
import styled from 'styled-components';
import { space, SpaceProps } from 'styled-system';
import { IArticle } from 'interfaces';
import { Flex, Heading, Text } from 'components';
import { scales as HeadingScale } from 'components/Heading/types';
import { ARTICLE_BASE_URL } from 'views/Article/types';
import { formatDate } from '../../../utils/dateTime';

interface Props extends SpaceProps {
    article: IArticle
}

const StyledLink = styled( Link )`
    display: block;
    border: 1px solid ${ ( { theme } ) => theme.colors.cardBorderSubtle };
    color: ${ ( { theme } ) => theme.colors.textSubtle };
    border-radius: 8px;
    padding: 16px;

    &:hover {
        border-color: ${ ( { theme } ) => theme.colors.cardBorder };
        ${ Heading }{
            text-decoration: underline;
        }
    }
    
    ${ space }
`;

const Footer = styled( Flex )`
    align-items: center;
    justify-content: space-between;
    margin-top: 1rem;
    
    ${ Text }{
        font-size: 12px;
        color: ${ ( { theme } ) => theme.colors.textSubtle };
    }
`;

const ArticleLink: React.FC<Props> = ( props ) => {
    const { article, ...otherProps } = props;

    return (
        <StyledLink
            as='a'
            href={ `${ ARTICLE_BASE_URL }/${ article.slug }` }
            { ...otherProps }
        >
            <Heading scale={ HeadingScale.SM }>
                { article.title }
            </Heading>
            { article.summary && (
                <Text>{ article.summary }</Text>
            ) }
            <Footer>
                <Text>{ formatDate( article.date ) }</Text>
                <Text>{ article.categories?.join( ' ' ) }</Text>
            </Footer>
        </StyledLink>
    );
};

export default ArticleLink;