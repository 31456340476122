import React, { useMemo } from 'react';
import { useParams } from 'react-router-dom';
import { Container, Page, PageHeader, Text, UnderlinedHeading } from 'components';
import { ICategory } from 'interfaces';

import articles from './tempArticles';
import categories, { AllArticlesCategory } from './tempCategories';
import ArticlesByCategory from './components/ArticlesByCategory';

const Articles: React.FC = () => {
    const { activeTab } = useParams<{ activeTab?: string }>();

    const activeCategory = useMemo<ICategory>( () => {
        return categories.find( category => category.slug === activeTab ) || AllArticlesCategory;
    }, [
        activeTab
    ] );

    return (
        <Page>
            <Container>
                <PageHeader/>

                <UnderlinedHeading mb='1rem'>
                    { activeCategory?.title }
                </UnderlinedHeading>

                <Text textAlign='center'>
                    { articles.length } articles
                </Text>

                <ArticlesByCategory
                    mt={ 40 }
                    defaultCategory={ activeCategory }
                />
            </Container>
        </Page>
    );
};

export default Articles;