import styled from 'styled-components';
import Text from '../Text/Text';
import { HeadingProps, scales, tags } from './types';

const style = {
    [scales.SM]: {
        fontSize: '16px',
        fontSizeLg: '16px',
    },
    [scales.MD]: {
        fontSize: '20px',
        fontSizeLg: '20px',
    },
    [scales.LG]: {
        fontSize: '24px',
        fontSizeLg: '24px',
    },
    [scales.XL]: {
        fontSize: '32px',
        fontSizeLg: '40px',
    },
    [scales.XXL]: {
        fontSize: '48px',
        fontSizeLg: '56px',
    },
};

const Heading = styled( Text ).attrs( { bold: true } )<HeadingProps>`
  font-size: ${ ( { scale } ) => style[scale || scales.MD].fontSize };
  line-height: 1.1;
  font-weight: 600;

  ${ ( { theme } ) => theme.mediaQueries.lg } {
    font-size: ${ ( { scale } ) => style[scale || scales.MD].fontSizeLg };
  }
`;

Heading.defaultProps = {
    as: tags.H2,
};

export default Heading;
