import { IArticle } from 'interfaces';

/**
 * This file is temporary!
 * It will be removed as soon as we can fetch these articles from a smart contract.
 */

const Articles: IArticle[] = [
    {
        title: 'The Decent Dream',
        summary: 'We all have dreams. Decent Times has a vision to educate communities about blockchain and Defi tech on a global scale. We have confidence our messages can enlighten and support beneficial improvements in the lives of virtually every individual who interacts with modern technology in today’s world.',
        slug: 'the-decent-dream',
        date: '2022-07-22 23:00:00',
        author: {
            name: 'TreeLyfeNBD',
            twitterUsername: 'TreeLyfeNBD'
        },
        categories: [ 'featured' ],
        arweaveObjectId: '4O3OsJN8ch4lIb61T5WQerXSUDZVQ7uOsAJoBXRTZ-I'
    },
    {
        title: 'Blockchain and Cryptography',
        summary: 'Safeguarding information has been paramount throughout our history and the practice of cryptography has long been used as a technology to encrypt and secure vital information or communications.',
        slug: 'blockchain-and-cryptography',
        date: '2022-07-22 23:00:00',
        author: {
            name: 'TreeLyfeNBD',
            twitterUsername: 'TreeLyfeNBD'
        },
        categories: [ 'blockchain' ],
        arweaveObjectId: '4O3OsJN8ch4lIb61T5WQerXSUDZVQ7uOsAJoBXRTZ-I'
    },
];

export default Articles;