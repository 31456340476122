import React from 'react';
import { Container, Page, PageHeader } from 'components';
import RotatingText from './components/RotatingText';
import ArticlesByCategory from '../Articles/components/ArticlesByCategory';

const Home: React.FC = () => {
    return (
        <Page>
            <Container pb={ 10 }>
                <PageHeader/>
                <RotatingText/>
                <ArticlesByCategory/>
            </Container>
        </Page>
    );
};

export default Home;