import React from 'react';
import { BrowserRouter, Route, Switch } from 'react-router-dom';
import ResetCSS from 'style/ResetCSS';
import GlobalStyle from 'style/Global';

import { Home, HOME_BASE_URL } from './views/Home';
import { About, ABOUT_BASE_URL } from './views/About';
import { Roadmap, ROADMAP_BASE_URL } from './views/Roadmap';
import { Articles, ARTICLES_BASE_URL } from './views/Articles';
import { Article, ARTICLE_BASE_URL } from './views/Article';
import NotFound from 'views/NotFound';

const App: React.FC = () => {
    return (
        <BrowserRouter>
            <ResetCSS/>
            <GlobalStyle/>
            <Switch>
                {/* Home */ }
                <Route exact path={ HOME_BASE_URL } component={ Home }/>

                {/* About */ }
                <Route exact path={ ABOUT_BASE_URL } component={ About }/>

                {/* Roadmap */ }
                <Route exact path={ ROADMAP_BASE_URL } component={ Roadmap }/>

                {/* List of Articles */ }
                <Route exact path={ `${ ARTICLES_BASE_URL }/:activeTab?` } component={ Articles }/>

                {/* Specific Article */ }
                <Route path={ `${ ARTICLE_BASE_URL }/:slug` } component={ Article }/>

                {/* 404 */ }
                <Route component={ NotFound }/>
            </Switch>
        </BrowserRouter>
    );
};

export default React.memo( App );
