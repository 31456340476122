import React from 'react';
import styled from 'styled-components';

interface Props {
    onClick: () => void
    isActive?: boolean
}

const StyledLink = styled.button`
    background-color: transparent;
    border: 0px none;
    height: 36px;
    line-height: 36px;
    padding: 0 12px;
    color: ${ ( { theme } ) => theme.colors.textSubtle };
    text-transform: uppercase;
    font-size: 18px;
    border-radius: 8px;
    margin-right: 0.5rem;
    cursor: pointer;

    &:hover {
        color: ${ ( { theme } ) => theme.colors.primary };
    }
`;

const ActiveStyledLink = styled( StyledLink )`
    font-weight: bold;
    background-color: ${ ( { theme } ) => theme.colors.cardBorderSubtle };
    color: ${ ( { theme } ) => theme.colors.primary };
`;

const CategoryLink: React.FC<Props> = ( props ) => {
    const { children, onClick, isActive } = props;

    return isActive ?
        <ActiveStyledLink>{ children }</ActiveStyledLink> :
        <StyledLink onClick={ onClick }>{ children }</StyledLink>;
};

CategoryLink.defaultProps = {
    isActive: false
};

export default CategoryLink;