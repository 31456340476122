import React from 'react';
import styled from 'styled-components';
import WalletButton from './WalletButton';

const DisconnectButton = styled( WalletButton )`
    background-color: rgba(255, 255, 255, .8);
    border: 2px solid ${ ( { theme } ) => theme.colors.primary };
    color: ${ ( { theme } ) => theme.colors.primary };
    
    &:hover {
        background: #ffffff;
    }
`;

const DisconnectWalletButton = ( props: any ) => {
    return (
        <DisconnectButton { ...props }>
            Disconnect wallet
        </DisconnectButton>
    );
};

export default DisconnectWalletButton;
