import { LayoutProps, SpaceProps } from 'styled-system';

export const animation = {
    WAVES: 'waves',
    PULSE: 'pulse',
} as const;

export const variant = {
    RECT: 'rect',
    CIRCLE: 'circle',
} as const;

export type Animation = typeof animation[keyof typeof animation];
export type Variant = typeof variant[keyof typeof variant];

export interface SkeletonProps extends SpaceProps, LayoutProps {
    animation?: Animation;
    variant?: Variant;
}

export const SKELETON_TEXT_HEIGHT = 25;
export const SKELETON_H1_HEIGHT = 40;
export const SKELETON_H2_HEIGHT = 30;