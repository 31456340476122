import React, { useMemo, useState } from 'react';
import { Box, Flex, Text } from 'components';
import CategoryLink from './CategoryLink';
import ArticleLink from './ArticleLink';
import { IArticle, ICategory } from 'interfaces';
import categories, { AllArticlesCategory } from '../tempCategories';
import articles from '../tempArticles';
import { SpaceProps } from 'styled-system';

interface Props extends SpaceProps {
    defaultCategory?: ICategory
}

const ArticlesByCategory: React.FC<Props> = ( props ) => {
    const { defaultCategory = AllArticlesCategory, ...otherProps } = props;
    const [ selectedCategory, setSelectedCategory ] = useState<ICategory>( defaultCategory );

    const articlesOfActiveCategory = useMemo<IArticle[]>( () => {
        if ( selectedCategory?.slug === AllArticlesCategory.slug ) return articles;
        return articles.filter( article => article.categories?.includes( selectedCategory?.slug ) );
    }, [
        selectedCategory
    ] );

    return (
        <Box { ...otherProps }>
            <Flex alignItems='center' justifyContent='center' mb={ 10 }>
                { categories.map( category => {
                    return (
                        <CategoryLink
                            key={ category.slug }
                            onClick={ () => setSelectedCategory( category ) }
                            isActive={ selectedCategory?.slug === category.slug }
                        >
                            { category.title }
                        </CategoryLink>
                    );
                } ) }
            </Flex>

            { articlesOfActiveCategory.length ?
                articlesOfActiveCategory.map( article =>
                    <ArticleLink
                        key={ article.title }
                        article={ article }
                        mb={ 20 }
                    />
                ) : (
                    <Text small color='textSubtle' textAlign='center'>
                        No articles found on this category
                    </Text>
                ) }
        </Box>
    );
};

export default ArticlesByCategory;