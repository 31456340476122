import React from 'react';
import { BoxProps, Container, Flex, Text, UnderlinedHeading } from 'components';
import styled from 'styled-components';

const ValueWrapper = styled( Flex )`
    padding: 16px 0;
    border-bottom: 1px solid ${ ( { theme } ) => theme.colors.cardBorder };
    
    &:last-child {
        border-bottom: 0 none;
    }
`;

const values = [
    {
        title: 'IMPROVEMENT',
        text: 'We believe that many facets of our everyday life can be improved, we value any and all ideas that can lead to an increased quality of living.'
    },
    {
        title: 'COMMUNITY',
        text: 'An important part of improvement, though it starts with an individual, ripples out into their local communities. If we strive to continuously make positive impacts, the long term effects can be profound.'
    },
    {
        title: 'EDUCATION',
        text: 'We have a focus on the cryptocurrency space, as an emerging asset class that is largely misunderstood. As it turns out, the further one goes into research and education, the more varying fields they realize all converge into this new and emerging space.'
    },
    {
        title: 'GLOBAL',
        text: 'This cornerstone piggybacks on our idea behind community and can be summed up in four words, "Think global, act local." What this means is our seemingly small personal contributions have lasting implications, even if we cannot see them firsthand or immediately.'
    },
];

type Props = BoxProps

const OurValues: React.FC<Props> = ( props ) => {
    return (
        <Container { ...props }>
            <UnderlinedHeading>Our Values</UnderlinedHeading>

            { values.map( ( value ) => {
                return (
                    <ValueWrapper key={ Math.random() }>
                        <Text>
                            <strong>{ value.title } - </strong> { value.text }
                        </Text>
                    </ValueWrapper>
                );
            } ) }
        </Container>
    );
};

export default OurValues;