import React from 'react';
import { Container, Page, PageHeader } from 'components';
import Mission from './components/Mission';
import OurValues from './components/OurValues';

const About: React.FC = () => {
    return (
        <Page>
            <Container>
                <PageHeader/>
                <Mission/>
                <OurValues mt={ 40 }/>
            </Container>
        </Page>
    );
};

export default About;