import styled from 'styled-components';

const WalletButton = styled.button`
    padding: 4px 6px;
    overflow: hidden;
    font-size: 14px;
    text-decoration: none;
    background-color: transparent;
    transition: 0.5s;
    cursor: pointer;
`;

export default WalletButton;