import React from 'react';
import styled from 'styled-components';
import WalletButton from './WalletButton';

const ConnectButton = styled( WalletButton )`
    background-color: rgba(255, 255, 255, .8);
    border: 2px solid ${ ( { theme } ) => theme.colors.primary };
    color: ${ ( { theme } ) => theme.colors.primary };
    
    &:hover {
        background: #ffffff;
    }
`;

const ConnectWalletButton = ( props: any ) => {
    return (
        <ConnectButton onClick={ () => alert( 'Pending' ) } { ...props }>
            Connect Wallet
        </ConnectButton>
    );
};

export default ConnectWalletButton;
