import React from 'react';
import { BoxProps, Container, Text, UnderlinedHeading } from 'components';

type Props = BoxProps

const Mission: React.FC<Props> = ( props ) => {
    return (
        <Container { ...props }>
            <UnderlinedHeading>Mission</UnderlinedHeading>
            <Text>
                DeCent Times aims to provide quality and factual content, on underreported news, covering a wide variety of topics, without a focus on any one specific geographic area. We plan to achieve this through a number of mediums, starting with a newsletter format, shipped biweekly. At DeCent Times, we believe in starting small and becoming something bigger than the sum of all parts, through healthy community development, meaningful engagement and production of thought provoking content.
            </Text>
        </Container>
    );
};

export default Mission;