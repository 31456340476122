import { DefaultTheme } from 'styled-components';

import { Breakpoints, Colors, MediaQueries, Shadows, Spacing } from './types';

import base from './base';
import { appColors } from './colors';
import { shadows } from './shadows';

export interface AppTheme {
    siteWidth: number;
    isDark: boolean;
    colors: Colors;
    breakpoints: Breakpoints;
    mediaQueries: MediaQueries;
    shadows: Shadows;
    spacing: Spacing;
}

export const appTheme: DefaultTheme = {
    ...base,
    isDark: false,
    colors: appColors,
    shadows: shadows,
};