import React from 'react';
import styled, { keyframes } from 'styled-components';
import { layout, space } from 'styled-system';
import { animation as ANIMATION, SKELETON_TEXT_HEIGHT, SkeletonProps, variant as VARIANT } from './types';

const waves = keyframes`
   from {
        left: -150px;
    }
    to   {
        left: 100%;
    }
`;

const pulse = keyframes`
    from {
        left: -150px;
    }
    to   {
        left: 100%;
    }
`;

const Root = styled.div<SkeletonProps>`
    min-height: 20px;
    display: block;
    background-color: ${ ( { theme } ) => theme.colors.backgroundDisabled };
    border-radius: ${ ( { variant } ) => (variant === VARIANT.CIRCLE ? '50%' : '4px') };
    
    ${ layout }
    ${ space }
`;

const Pulse = styled( Root )`
    position: relative;
    overflow: hidden;
    background-color: ${ ( { theme } ) => theme.colors.backgroundDisabled };
    min-height: 1rem;
    min-width: 1rem;
    
    &::before {
        content: '';
        display: block;
        position: absolute;
        left: -150px;
        top: 0;
        height: 100%;
        width: 150px;
        background: linear-gradient(to right, transparent 0%, #FAFAFA 50%, transparent 100%);
        animation: ${ pulse } 1s cubic-bezier(0.4, 0.0, 0.2, 1) infinite;
    }
`;

const Waves = styled( Root )`
    position: relative;
    overflow: hidden;
    transform: translate3d(0, 0, 0);
    &:before {
        content: "";
        position: absolute;
        background-image: linear-gradient(90deg, transparent, rgba(243, 243, 243, 0.5), transparent);
        top: 0;
        left: -150px;
        height: 100%;
        width: 150px;
        animation: ${ waves } 2s cubic-bezier(0.4, 0, 0.2, 1) infinite;
    }
`;

const Skeleton: React.FC<SkeletonProps> = ( { variant = VARIANT.RECT, animation = ANIMATION.PULSE, ...props } ) => {
    if ( animation === ANIMATION.WAVES ) {
        return <Waves variant={ variant } { ...props } />;
    }

    return <Pulse variant={ variant } { ...props } />;
};

Skeleton.defaultProps = {
    height: SKELETON_TEXT_HEIGHT
};

export default Skeleton;
