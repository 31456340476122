import React from 'react';
import { Button, Container, Flex, Heading, Page, PageHeader } from 'components';
import { Link } from 'react-router-dom';

const NotFound = () => {
    return (
        <Page>
            <Container>
                <PageHeader/>
                <Flex
                    flexDirection='column'
                    alignItems='center'
                    justifyContent='center'
                >
                    <Heading mb={ 2 } scale='xl'>Oops 😅</Heading>
                    <Heading mb={ 40 }>Page not found!</Heading>
                    <Button as={ Link } to="/" scale="sm" variant="secondary">
                        Back Home
                    </Button>
                </Flex>
            </Container>
        </Page>
    );
};

export default NotFound;
