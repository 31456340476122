import React from 'react';
import { HelmetProvider } from 'react-helmet-async';
import { ThemeProvider } from 'styled-components';
import { appTheme } from './config/theme';

const Providers: React.FC = ( { children } ) => {
    return (
        <ThemeProvider theme={ appTheme }>
            <HelmetProvider>
                { children }
            </HelmetProvider>
        </ThemeProvider>
    );
};

export default Providers;
