export default [
    {
        phase: 'PHASE 1',
        heading: 'Curate a selection of multi-topic articles for readers to become acquainted with DeCent Times, share our writers talent, and gain feedback on content. During this time we will also:',
        topics: [
            'Implement Arweave decentralized storage for all content within the site.',
            'Begin work on web3 based subscriptions for DeCent Times and governance.',
            'Explore community partnerships and expand writing staff.',
        ]
    },
    {
        phase: 'PHASE 2',
        heading: 'Launch Discord and forums based on the site to gain community engagement. Both platforms will be used to guide the development of our vision of Journalism as a Service (Jaas) as well as informational pieces for the community. At this point in time we will have:',
        topics: [
            'Finished the framework for our web3 based subscriptions and governance to allow our community to both help support decentralized information and the writers at DeCent Times.',
            'Solidified partnerships with community writers, platforms, and educators within the space',
            'Begin working on a Jaas portal for writers to connect with clients and provide their skills and services seamlessly through the power of web3 transactions',
        ]
    },
    {
        phase: 'PHASE 3',
        heading: 'Release our Jaas portal and begin providing journalistic services to our community and clients.',
        topics: [
            'Continue to provide timely content to our readers',
            'Contract out services through our Jaas portal',
            'Enable our readers to subscribe using cryptocurrency to support our community and it’s work',
        ]
    },
];