import { AutoRenewIcon } from '../Svg';
import React from 'react';
import { SpaceProps } from 'styled-system';

interface LoadingIconProps extends SpaceProps {
    color?: string
}

const LoadingIcon: React.FC<LoadingIconProps> = ( props ) => {
    const { color, ...otherProps } = props;
    return <AutoRenewIcon spin color={ color } { ...otherProps }/>;
};

LoadingIcon.defaultProps = {
    color: 'primary'
};

export default LoadingIcon;