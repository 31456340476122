import { Colors } from './types';

export const baseColors = {
    primary: '#333333',
    secondary: '#555555',
    tertiary: '#723149',
};

export const alertsColors = {
    success: '#31D0AA',
    warning: '#FFB237',
    failure: '#ed4b4b',
};

export const appColors: Colors = {
    ...baseColors,
    ...alertsColors,
    background: '#e7e7e9',
    backgroundDisabled: '#ebeeee',
    text: '#181513',
    textDisabled: '#c0cacd',
    textSubtle: '#9e9e9e',
    cardBorder: '#bababa',
    cardBorderSubtle: '#ccc',
    inputBackground: '#FEFEFE',
    inputBorder: '#ddd',
    inputHoverBackground: '#EFEFEF',
    inputFocusedBorder: '#CACACA',
};