import React from 'react';
import styled from 'styled-components';
import { ConnectWalletButton, Container, Heading } from '../';
import { Flex } from '../Box';
import { Link } from 'react-router-dom';

const Wrapper = styled.div`
    width: 100%;
    padding: 1rem 0.5rem;
`;

const MenuLink = styled( Link )`
    display: block;
    padding: 1rem;
    margin: 0 1rem;
    padding: 0;
    font-weight: 600;
    font-size: 18px;
    
    &:hover {
        text-decoration: underline;
    }
`;

const PageHeader: React.FC = () => {
    return (
        <Container mb={ 40 }>
            <Wrapper>
                <Flex alignItems="center" justifyContent="space-between">
                    <Heading>
                        <Link to="/">
                            DeCent Times
                        </Link>
                    </Heading>

                    <Flex alignItems="center" justifyContent="center" flex={ 1 }>
                        <MenuLink to='/about'>
                            about
                        </MenuLink>
                        <MenuLink to='/roadmap'>
                            roadmap
                        </MenuLink>
                        <MenuLink to='/articles'>
                            articles
                        </MenuLink>
                    </Flex>

                    <Flex alignItems="center" justifyContent="center">
                        <ConnectWalletButton/>
                    </Flex>
                </Flex>
            </Wrapper>
        </Container>
    );
};

export default PageHeader;