import React from 'react';
import { Container, Page, PageHeader, Text, UnderlinedHeading } from 'components';
import roadmapItems from './RoadmapItems';
import styled from 'styled-components';

const RoadmapPhase = styled.div`
    margin-bottom: 3rem;
`;

const Ul = styled.ul`
    margin-top: 1rem;
    
    & > li {
        margin-bottom: 0.5rem;
    }
`;

const Roadmap: React.FC = () => {
    return (
        <Page>
            <Container>
                <PageHeader/>

                <UnderlinedHeading>
                    The DeCent Times Roadmap
                </UnderlinedHeading>

                { roadmapItems.map( ( item ) => {
                    return (
                        <RoadmapPhase key={ item.phase }>
                            <Text
                                bold
                                textAlign='center'
                                mb={ 1 }
                            >
                                { item.phase }
                            </Text>
                            <Text bold>{ item.heading }</Text>
                            { item.topics.length && (
                                <Ul>
                                    { item.topics.map( topic => <li key={ Math.random() }>{ topic }</li> ) }
                                </Ul>
                            ) }
                        </RoadmapPhase>
                    );
                } ) }
            </Container>
        </Page>
    );
};

export default Roadmap;