import { ICategory } from 'interfaces';

/**
 * This file is temporary!
 * It will be removed as soon as we can fetch the articles from a smart contract.
 */

const Categories: ICategory[] = [
    { title: 'All articles', slug: 'all-categories' },
    { title: 'Featured', slug: 'featured' },
    { title: 'Blockchain', slug: 'blockchain' },
    { title: 'Misc', slug: 'misc' },
];

export default Categories;

export const AllArticlesCategory = Categories[0];