import React, { useEffect, useState } from 'react';
import { FetchStatus } from 'config/types';
import { ReactMarkdown } from 'react-markdown/lib/react-markdown';
import remarkGfm from 'remark-gfm';
import useArweaveStorage from 'hooks/useArweaveStorage';
import { Flex, LoadingIcon, Text } from 'components';
import './ArweaveArticle.css';

interface Props {
    objectId: string
}

const ArweaveArticle: React.FC<Props> = ( props ) => {
    const { objectId } = props;

    const [ fileContents, setFileContents ] = useState( '' );
    const [ fetchStatus, setFetchStatus ] = useState<FetchStatus>( FetchStatus.PENDING );

    const { getContents } = useArweaveStorage();

    useEffect( () => {
            const fetchContents = async () => {
                try {
                    const contents = await getContents( objectId );
                    setFileContents( contents );
                    setFetchStatus( FetchStatus.SUCCESS );
                } catch ( err ) {
                    setFetchStatus( FetchStatus.FAILED );
                }
            };

            if ( objectId ) {
                fetchContents().catch( err => console.warn( `Error fetching article contents from arweave: ${ err?.message }` ) );
            }
        }, [
            objectId,
            setFileContents,
            setFetchStatus,
        ],
    );

    return (
        <div>
            { fetchStatus === FetchStatus.PENDING && (
                <Flex>
                    <LoadingIcon mr={ 2 }/>
                    <Text>Loading article contents...</Text>
                </Flex>
            ) }

            { fetchStatus === FetchStatus.FAILED && (
                <Text color='failure'>Failed loading contents.</Text>
            ) }

            { fetchStatus === FetchStatus.SUCCESS && (
                <ReactMarkdown
                    className="markdown-body"
                    remarkPlugins={ [ remarkGfm ] }
                >
                    { fileContents }
                </ReactMarkdown>
            ) }
        </div>
    );
};

export default ArweaveArticle;