import React from 'react';
import ReactRotatingText from 'react-rotating-text';
import styled from 'styled-components';
import { Container, Flex } from 'components';
import LogoImage from 'assets/images/DecentLogo.png';
import sentences from './Sentences';

const LeftContainer = styled.div`
    flex: 1;
    
    * {
        font-family: Montserrat;
    }
`;

const RotatingTextWrapper = styled.div`
    padding: 0;
    margin-bottom: 0;
    font-size: 18px;
    font-weight: 300;
    margin-top: 1rem;
    font-weight: 200;    
`;

const WelcomeWrapper = styled.div`
    font-size: 3rem;
`;

const LogoWrapper = styled.div`
    width: 250px;
    
    & > img {
        width: 100%;
    }
`;

const RotatingText: React.FC = () => {
    return (
        <Container py={ 70 }>
            <Flex alignItems='center' justifyContent='center'>
                <LeftContainer>
                    <WelcomeWrapper>
                        Welcome,<br/>
                        To DeCent Times.
                    </WelcomeWrapper>
                    <RotatingTextWrapper>
                        We are a <ReactRotatingText items={ sentences }/>
                    </RotatingTextWrapper>
                </LeftContainer>

                <LogoWrapper>
                    <img src={ LogoImage } alt="DeCent Times"/>
                </LogoWrapper>
            </Flex>
        </Container>
    );
};

export default RotatingText;