import { HTTP_STATUS_OK } from '../config/constants/http';

const useArweaveStorage = () => {

    const getContents = async ( objectId: string ) => {
        const rawResponse = await fetch( `https://arweave.net/${ objectId }`, {
            method: 'GET',
        } );

        const statusCode = rawResponse.status;

        if ( statusCode !== HTTP_STATUS_OK ) {
            throw new Error( `Failed fetching file contents from arweave - Response code ${ statusCode }.` );
        }

        return await rawResponse.text();
    };

    const saveNewFile = async () => {
        // TODO
    };

    return {
        getContents,
        saveNewFile,
    };
};

export default useArweaveStorage;