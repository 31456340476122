import { createGlobalStyle } from 'styled-components';
import { AppTheme } from 'config/theme';

declare module 'styled-components' {
    /* eslint-disable @typescript-eslint/no-empty-interface */
    export interface DefaultTheme extends AppTheme {
    }
}

const GlobalStyle = createGlobalStyle`
    * {
        font-family: -apple-system, BlinkMacSystemFont, Segoe UI, Helvetica, Arial, sans-serif, Apple Color Emoji, Segoe UI Emoji;
        -webkit-font-smoothing: antialiased;
        -moz-osx-font-smoothing: grayscale;
    }
    
    html, body {
        height: 100vh;
    }
    
    body {
        background-color: ${ ( { theme } ) => theme.colors.background };
        color: ${ ( { theme } ) => theme.colors.text };
        
        img {
            height: auto;
            max-width: 100%;
        }
    }
    
    #root {
        min-height: 100%;
    }
`;

export default GlobalStyle;
